import React, { useEffect, useState } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import { Link, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import IMG from '../Remind/img/img-remind.jpg';
import IMG_MOBILE from '../Remind/img/img-remind-mobile.jpg';
import { ResetPass } from '../../features/user/authService';

function NewPassPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const [tempToken, setTempToken] = useState('');
  const [tickValues, setTickValues] = useState([false,false,false,false,false,false]);

  const onSubmit = ({password, password2}: FieldValues) => {
    dispatch(ResetPass({pass: password, token: tempToken, navigate: navigate}))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(()=>{
    if (location.search.indexOf("reset") !== -1 ) {
      let token = location.search.split("=")[1];
      setTempToken(token)
    }
  },[])

  const handleChange = (e: any) => {
    const value = e.target.value;
    const tickValues = [];
    /[a-z]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[A-Z]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[0-9]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    /[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]+/.test(value) ? tickValues.push(true) : tickValues.push(false);
    value.length >= 8 ? tickValues.push(true) : tickValues.push(false);
    /\s/.test(value) ? tickValues.push(false) : tickValues.push(true);
    setTickValues(tickValues)
  }

  return (
    <PageLayout>
      <div className="page Login form-columns">
        <div className="image-column">
          <img className="form-image" src={IMG} />
        </div>
        <div className="form-column">
          <h1>Ustaw hasło</h1>
          <img className="form-image-mobile" src={IMG_MOBILE} />
          <p className="text-center"><strong>Min. 8 znaków, 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny</strong></p>
          <form>
            <div className="input-item">
              <label htmlFor="password">Hasło</label>
              <input className={ errors.password && 'error-input' } type="password" id="password" placeholder="Wpisz hasło" {...register("password", {required: true, pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]).{8,}$/, onChange: (e) => { handleChange(e)}})} ></input>
              {errors.password && errors.password.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.password && errors.password.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <div className="ticks">
              <div className={ tickValues[0] ? "tick active" : "tick" }>Mała litera</div>
              <div className={ tickValues[1] ? "tick active" : "tick" }>Wielka litera</div>
              <div className={ tickValues[2] ? "tick active" : "tick" }>Cyfra</div>
              <div className={ tickValues[3] ? "tick active" : "tick" }>Znak specjalny</div>
              <div className={ tickValues[4] ? "tick active" : "tick" }>Minimum 8 znaków</div>
              <div className={ tickValues[5] ? "tick active" : "tick" }>Brak spacji</div>
            </div>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Zapisz i zaloguj się</Button>
            <div className="text-center margin-20">
              <Link to="/">Powrót do logowania</Link>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}

export default NewPassPage
