import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./News.scss";
import DATA from "../../data/owner-results-date-sort.json";
import { useAppDispatch, useAppSelector, useOnScreen } from "../../hooks";
import { selectNewsList } from "../../features/news/newsSlice";
import { GetNews } from "../../features/news/newsService";
import NewsListItem from "./NewsListItem/NewsListItem";
import { ReactComponent as BtnIcon } from "./img/btn-icon.svg";

function News() {
  const showItem = 4;
  const [itemToShow, setItemToShow] = useState(showItem);
  const newsList = useAppSelector(selectNewsList);
  const showMoreCta = newsList.length > 1;
  const dispatch = useAppDispatch()

  console.log(newsList)

  useEffect(()=>{
    if (newsList.length === 0) {
      dispatch(GetNews(0))
    }
  }, [])
  
  const handleShowMore = () => {
    setItemToShow((prevState) => prevState + showItem);
  };

  return (
    <PageLayout>
      <div className="page News">
        <div className="title">
          <h1>News</h1>
          <p>Artykuły przedstawiają aktualności z serwisu BAT Partners.</p>
        </div>
        <div className="news-wrapper">
          <ul className="news-list">
            {newsList.map((news: any) => (
              <NewsListItem key={news.title} news={news} />
            ))}
            {showMoreCta && (
              <button className="show-more-cta" onClick={handleShowMore}>
                Zobacz więcej <BtnIcon />
              </button>
            )}
          </ul>
        </div>
      </div>
    </PageLayout>
  );
}

export default News;
