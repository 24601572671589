import {
  useLocation,
  Link
} from 'react-router-dom'
import { useWindowDimensions, useScrollPosition, useAppDispatch, useAppSelector} from '../../hooks';
import { ReactComponent as IconMenu } from './img/icon-menu.svg';
import { ReactComponent as Logo } from './img/logo-owner.svg';
import ButtonBack from '../Button/ButtonBack/ButtonBack';
import './Header.scss';
import { openMenu } from '../../features/ui/uiSlice';
import { ReactComponent as ColorLine } from "./img/kreski.svg";
import { selectUserData } from '../../features/user/userSlice';

interface Props {
  castrated?: boolean
}

function Header({castrated}: Props) {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const {width, height} = useWindowDimensions();
  const scrollPosition = useScrollPosition();
  const changeHeaderToFixed = scrollPosition > 28;
  const user = useAppSelector(selectUserData);

  return (
    <>
    { location.pathname.indexOf('/mbat') === -1 &&
      <header className={changeHeaderToFixed ? "Header fixed" : "Header"}>
        { castrated ? 
          <>
            <ColorLine className="color-line" preserveAspectRatio="none" />
            <div className="header-inner">
              <div className="logo"><Logo/></div>
            </div>
          </>
          :
          <>
            <ColorLine className="color-line with-info" preserveAspectRatio="none" />
            <div className="header-inner with-info">
              <div className="nav-trigger" onClick={ ()=>{ dispatch(openMenu()) }}><IconMenu /></div>
              <div className="user-name">
                <div className="name">{ user?.owner?.firstName }</div>
                <div className="title">Właściciel</div>
              </div>
              { width < 1270 ?
                  location.pathname != '/dashboard' ? 
                  <ButtonBack/> : <div className="logo"><Logo/></div>
                : <div className="logo"><Link to="/dashboard"><Logo role="link"/></Link></div>
              }
            </div>
          </>
        }
      </header>
    }
    </>
  );
}

export default Header
