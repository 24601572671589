import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Dashboard.scss";
import DashboardLineChart from "./DashboardLineChart";
import DATA from "../../data/owner-results-date-sort.json";
import { ChartType, DateType } from "./DashboardLineChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as ArrowNext } from "./arrow-next.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { GetStatsDay, GetStatsMonth, GetStatsYear } from "../../features/stats/statsService";
import { selectEmployees, selectStatsData } from "../../features/stats/statsSlice";
import 'dayjs/locale/pl';
import { isTemplateMiddle } from "typescript";
import { selectAuthState, selectToken, selectUserData } from "../../features/user/userSlice";
import Button from "../../components/Button/Button";
import { API_URL, appFetch } from "../../fetch";
import { useParams, useSearchParams } from "react-router-dom";
import { UserDetails } from "../../features/user/authService";
import { ReactComponent as Logo } from '../../components/header/img/logo-owner.svg';
import Loading from "../../components/Loading/LoadingScreen/Loading";

interface DateSelectProps {
  dateCounter: number,
  dateType: DateType,
  setDateCounter: any
}

function DateSelect({dateCounter, dateType, setDateCounter}:DateSelectProps) {

  const [select, setSelect] = useState(<></>)

  useEffect(()=>{
    switch(dateType) {
      case 'week':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'week').startOf('week').format('DD.MM')+' - '+dayjs().add(dateCounter, 'week').endOf('week').format('DD.MM') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'month':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().locale('pl').add(dateCounter, 'month').format('MMMM YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'year':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'year').format('YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'full':
        setSelect(
          <div className="DateSelect">
            <div className="date-range">2021 - {dayjs().format('YYYY')}</div>
          </div>
        )
      break;
    }
  },[dateCounter, dateType])
  return (
    <>
      { select }
    </>
  )
}

function MBAT() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectAuthState);
  const user = useAppSelector(selectUserData);
  const token = useAppSelector(selectToken)
  const params = useParams()
  const [showEmployees, setShowEmployees] = useState(false)
  const [showInactiveEmployees, setShowInactiveEmployees] = useState(false)
  const [chartType, setChartType] = useState<ChartType>("device")
  const [dateType, setDateType] = useState<DateType>("month");
  const chartData = useAppSelector(selectStatsData)
  const devicesData = {
    device: chartData.reduce((sum:number, item:any) => sum + item.shops[0].device, 0),
    referral: chartData.reduce((sum:number, item:any) => sum + item.shops[0].referral, 0),
    balance: chartData.reduce((sum:number, item:any) => sum + item.shops[0].balance, 0),
  }
  const employees = useAppSelector(selectEmployees);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>([]);

  const colors = ['#15AFE6', '#FF0321', '#FF8D03', '#009B3E', '#8000FF'];
  const text = {
    device: {
      name: "urządzeń",
      title: "Całkowita ilość sprzedaży"
    },
    referral: {
      name: "poleceń",
      title: "Całkowita ilość poleceń"
    },
    balance: {
      name: "PLN",
      title: "Całkowita zebrana kwota"
    },
  }

  const [dateCounter, setDateCounter] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0)

  useEffect(()=>{
    if (user?.owner?.firstName) {
      getStatsData()
    }
  }, [dateCounter, dateType, user])

  const getStatsData = () => {
    if (dateType === 'week') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'week').startOf('week').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'week').endOf('week').format('YYYY-MM-DD')}))
    } else if (dateType === 'month') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'month').startOf('month').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'month').endOf('month').format('YYYY-MM-DD')}))
    } else if (dateType === 'year') {
      dispatch(GetStatsMonth({from: dayjs().add(dateCounter, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'year').endOf('year').format('YYYY-MM-DD')}))
    } else if (dateType === 'full') {
      dispatch(GetStatsYear({from: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().endOf('year').format('YYYY-MM-DD')}))
    }
  }

  useEffect(() => {
    if (params.token) {
      dispatch(UserDetails({navigate: false, isRedirect: false, token: params?.token, overide: true}))
    } else {
      if (!params.error) getLoginURL();
    }
  }, [params?.token])

  useEffect(()=>{
    setSelectedEmployeesData(employees.filter((item:any, key:number)=> selectedEmployees.indexOf(item.referralCode) > -1))
  }, [selectedEmployees])

  const toggleEmployee = (id:string) => {
    if (selectedEmployees.indexOf(id) > -1) {
      setSelectedEmployees(selectedEmployees.filter((item:any)=> item !== id))
    } else {
      if (selectedEmployees.length < 5)
        setSelectedEmployees([...selectedEmployees, id])
    }
  }

  const getLoginURL = () => {
    appFetch("POST", API_URL, "/integration/mbat ", false, false, (result: any) => {
      if(result.status && result.status.success) {
        window.location = result.data.url
      } else {
        
      }
    }, (error: any) => {

    })
  }

  return (
  <PageLayout>
    { !user?.owner?.firstName && !params?.error ? <Loading color={true} /> : null }
    { params?.error &&
      <div className="page mbat">
        { params?.error === "error_user_not_found" ?
          <div className="error-message">
            <h2>Nie posiadasz jeszcze konta w serwisie BAT Owner, zarejestruj się.<br/>
            Pamiętaj by użyć tego samego maila do rejestracji, który masz w mBAT.</h2>
            <Button path={window.location.origin+"/rejestracja"}>Zarejestruj się do serwisu Owner</Button>
          </div>  
          :
          <div className="error-message">
            <h2>Ups! Coś poszło nie tak!</h2>
          </div>
      }
      </div>
    }
    { user?.owner?.firstName &&
      <div className="page mbat">
        <div className="dash-head">
          <div className="sales-point"><span>Sprzedaż miesięczna</span></div>
          <div className="owner-info">
            <div className="info-top">
              <div className="bat-logo"><Logo/></div><p>Przejdź do BAT Owner, żeby zobaczyć więcej</p>
            </div>
            <Button path={window.location.origin}>Zobacz więcej</Button>
          </div>
        </div>
        <div className="dropshadow-box chart-box">
          <div className="chart-head">
            <div className="chart-options">
              <div className="data-options">
              <p className="sold-devices">Sprzedane w tym okresie - <strong>{ devicesData[chartType] } <span>{text[chartType].name}</span></strong></p>
              </div>
              <div className="date-options">
                <DateSelect dateCounter={dateCounter} dateType={dateType} setDateCounter={setDateCounter} />
              </div>
            </div>
          </div>
          <DashboardLineChart type={chartType} dateType={dateType} employeesData={selectedEmployeesData} employees={showEmployees} monthDays={parseInt(dayjs().add(dateCounter, 'month').endOf('month').format('DD'))} />
        </div>
      </div>
    }
  </PageLayout>
  );
}

export default MBAT;
