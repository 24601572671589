import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { useState, useEffect, useRef } from "react";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useOnScreen(options: IntersectionObserverInit | undefined) {
  const screen = getWindowDimensions();
  const ref = useRef<HTMLDivElement>(null);

  const [visable, setVisable] = useState(false);
  useEffect(() => {
    let refVar = ref.current;
    if (typeof window !== "undefined") {
      const observer = new IntersectionObserver(([entry]) => {
        setVisable(entry.isIntersecting);
      }, options);

      if (refVar) {
        observer.observe(refVar);
      }

      return () => {
        if (refVar) {
          observer.unobserve(refVar);
        }
      };
    }
  }, [ref, options]);

  return [ref, visable] as const;
}

export function useClickOutside(handler: () => void) {
  let domNode = useRef<any>(null);

  useEffect(() => {
    let onClickHandler = (event: any) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };
    document.addEventListener("mousedown", onClickHandler);
    return () => {
      document.removeEventListener("mousedown", onClickHandler);
    };
  });

  return domNode;
}

export const useColorPicker = (category: string) => {
  let color;
  let value = category?.toLowerCase();

  switch (value) {
    case "wydarzenie":
      color = "#FE328B";
      break;
    case "konkurs":
      color = "#0CB8E6";
      break;
    case "quiz":
      color = "#3BBB69";
      break;

    default:
      color = "#fff";
  }
  return color;
};

export const romanize = (num: number) => {
  const roman: {[r: string]: number} = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
 
  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);
  }, []);

  return { isMobile };
}

export const useAnalyticsEventTracker = (category="Batpartners") => {
  const eventTracker = (action = "action", label = "label") => {
    //ReactGA.event({category, action, label});
  }
  return eventTracker;
}