import React from "react";
import "./Button.scss";
import { Link } from "react-router-dom";

interface Props {
  path?: string;
  color?: "white" | "blue" | "orange";
  onClick?: () => void;
  children: any;
  className?: string;
}

const Button: React.FC<Props> = ({ path, color = 'blue', onClick, children, className }) => {
  return !path ? (
    <button className={`Button ${color} ${className}`} onClick={onClick}><span>{ children }</span></button>
  ) : (
    path.indexOf('http') > -1 ? 
    <a className={`Button ${color} ${className}`} href={path || ''} target="_blank"><span>{ children }</span></a>
    : <Link className={`Button ${color} ${className}`} to={path || ''}><span>{ children }</span></Link>
  );
};

export default Button;
