import { createSlice, isAnyOf, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { GetRankingConsumer, GetRankingSales } from './rankingService';
import { RootState } from '../../store';

export interface RankingState {
  consumer: any,
  sales: any,
  isLoading: boolean,
}

const initialState: RankingState = {
  consumer: [],
  sales: [],
  isLoading: false
};

export const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(
      GetRankingConsumer.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.consumer = action.payload.data.ranking;
      }
    )
    .addCase(
      GetRankingSales.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.sales = action.payload.data.ranking;
      }
    )
    .addMatcher(
      isAnyOf(GetRankingConsumer.pending, GetRankingSales.pending),
      (state) => {
        state.isLoading = true;
      }
    )
  },
});

// Exporting pieces of state to be used in components with useSelector Hook
export const selectRankingConsumer = (state: RootState) => state.ranking.consumer;
export const selectRankingSales = (state: RootState) => state.ranking.sales;

// Reducer export
export default rankingSlice.reducer