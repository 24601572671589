import { createSlice, isAnyOf, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { GetStatsDay, GetStatsMonth, GetStatsYear } from './statsService';
import { RootState } from '../../store';
//import _ from 'lodash';

export interface RankingState {
  data: any,
  employees: any,
  isLoading: boolean,
}

const initialState: RankingState = {
  data: [],
  employees: [],
  isLoading: false
};

const getEmployees: any = (data: any) => {
  let employees:any = [];
  data.forEach((day:any, index:number) => {
    if(index === 0) {
      employees = [...day.clerks]
    } else {
      day.clerks.forEach((clerk:any, cindex:number) => {
        employees[cindex].device += clerk.device
        employees[cindex].referral += clerk.referral
        employees[cindex].balance += clerk.balance
      })
    }
  });
  return employees;
}

export const rankingSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(
      GetStatsDay.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data
        state.employees = getEmployees(JSON.parse(JSON.stringify(action.payload.data)))
      }
    )
    .addCase(
      GetStatsMonth.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data
        state.employees = getEmployees(JSON.parse(JSON.stringify(action.payload.data)))
      }
    )
    .addCase(
      GetStatsYear.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data
        state.employees = getEmployees(JSON.parse(JSON.stringify(action.payload.data)))
      }
    )
    .addMatcher(
      isAnyOf(GetStatsDay.pending, GetStatsMonth.pending, GetStatsYear.pending),
      (state) => {
        state.isLoading = true;
      }
    )
  },
});

// Exporting pieces of state to be used in components with useSelector Hook
export const selectStatsData = (state: RootState) => state.stats.data
export const selectEmployees = (state: RootState) => state.stats.employees

// Reducer export
export default rankingSlice.reducer