import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch } from "../../fetch";
import type { RootState } from "../../store";
import { showDrawer } from "../ui/uiSlice";

const headers = {
  "Content-Type": "application/json",
};

interface UserDetailsArgs {
  navigate: any,
  isRedirect: boolean,
  token: string | null,
  overide?: boolean
}

export const UserDetails = createAsyncThunk<any, UserDetailsArgs>(
  "auth/UserDetails", 
  async ({navigate, isRedirect, token, overide}, {dispatch, getState}) => {
    const state = getState() as RootState;

    const response = await appFetch("POST", API_URL, "/owner/details", false, token ? token : state.user.token.uuid, (result: any) => {
      if(result.status && result.status.success) {
        if (token) localStorage.setItem("token", JSON.stringify({uuid:token}));
        if(isRedirect) {
          
          navigate && navigate("/dashboard", {replace: true})
        }
        return result.data;
      } else {
        localStorage.removeItem("token");
        if (isRedirect) { 
          navigate("/", {replace: true});
        } else {
          //window.location.replace('/')
        }
      }
    }, (error: any) => {

    }, overide)
    return {user: response, token: {uuid: token}}
});

interface LoginArgs {
  navigate: any,
  credentials: {
    emailAddress: string,
    password: string
  }
}

export const Login = createAsyncThunk<any, LoginArgs>(
  "user/Login",
  async ({credentials, navigate}, {dispatch, rejectWithValue}) => {
    try {
      return appFetch("POST", API_URL, "/owner/login", {owner: credentials}, false, (result: any) => {
        if(result.status && result.status.success) {
            localStorage.setItem("token", JSON.stringify(result.data.token));
            dispatch(UserDetails({navigate: navigate, isRedirect: false, token: result.data.token.uuid}))
            navigate("/dashboard", {replace: true})
            return result.data.token;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: {name: 'generic'} }))
      })
    } catch (err: any) {
      // Custom error message from Server returned to reducer using rejectWithValue
      // This error is available in action.payload unlike action.error for unhandled errors
      return rejectWithValue(err.response.data);
    }
  }
);

interface RegisterArgs {
  data: {
    firstName: string,
    lastName: string,
    salesPoint?: string,
    emailAddress: string,
    mobilePhone: string,
    hostessCode: string,
    ageVerified: boolean
    consentAgreement: boolean,
    marketingConsent: boolean,
    phoneContact: string,
    emailContact: string,
  }
}

export const Register = createAsyncThunk<any, RegisterArgs>(
  "user/Register",
  async ({data}, { dispatch}) => {
    return appFetch("POST", API_URL, "/owner/register", {owner: data,
      reset: {
          return: {
              url: "https://"+window.location.hostname+"/nowe-haslo/?reset="
          }
      }}, false, (result: any) => {
      if(result.status && result.status.success) {
        return true;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
        return false;
      }
    }, (error: any) => {
      dispatch(showDrawer({type: 'error', data: {name: 'generic'} }))
    })
  }
);

interface LogoutArgs {
  navigate: any
}

export const Logout = createAsyncThunk<any, LogoutArgs>("user/Logout", 
  async ({navigate}, {dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/owner/logout", false, state.user.token.uuid , (result: any) => {
      if(result.status && result.status.success) {
        localStorage.removeItem("token");
        navigate("/wylogowanie", {replace: true})
      } else {
        localStorage.removeItem("token");
        navigate("/wylogowanie", {replace: true})
      }
  }, (error: any) => {
    //dispatch(showDrawer('error', { error: 'generic' }))
  })
});

interface RequestResetArgs {
  email: string
}

export const RequestReset = createAsyncThunk<any, RequestResetArgs>(
  "user/RequestReset",
  async (email, { dispatch, getState}) => {
    const state = getState() as RootState;
    return appFetch("POST", API_URL, "/owner/request-reset-password", 
      {
      owner: { emailAddress: email },
      reset: {
          return: {
              url: "https://"+window.location.hostname+"/nowe-haslo/?reset="
          }
      }}, state.user.token.uuid, (result: any) => {
      if(result.status && result.status.success) {

          return true;
      } else {
        dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))  
          return false;
      }
    }, (error: any) => {
      dispatch(showDrawer({type: 'error', data: {name: 'generic'} }))
    })
  }
);

interface ResetArgs {
  pass: string,
  token: string,
  navigate: any
}

export const ResetPass = createAsyncThunk<any, ResetArgs>(
  "user/ResetPass",
  async ({pass, token, navigate}, { dispatch, rejectWithValue}) => {
    try {
      appFetch("POST", API_URL, "/owner/reset-password", {owner:{ password: pass}}, token, (result: any) => {
        if(result.status && result.status.success) {
            navigate("/login", {replace: true})
            dispatch(showDrawer({type: 'info', data:{name: 'can_login'}}))
            return true;
        } else {
          dispatch(showDrawer({type: 'error', data:{name: result.data.error, errorCode: result.meta.ts }}))
            return false;
        }
      }, (error: any) => {
        dispatch(showDrawer({type: 'error', data: {name: 'generic'} }))
      })
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);