import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Nav from "./components/Nav/Nav";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import Register from "./pages/Register/Register";
import NewPass from "./pages/NewPass/NewPass";
import Remind from "./pages/Remind/Remind";
import Page404 from "./pages/404/Page404";
import Dashboard from "./pages/Dashboard/Dashboard";
import Ranking from "./pages/Ranking/Ranking";
import News from "./pages/News/News";
import NewsDetails from "./pages/News/NewsDetails/NewsDetails";
import Footer from "./components/Footer/Footer";
import MBAT from "./pages/MBAT/MBAT";
import Drawer from "./components/Drawer/Drawer";

function AppRoutes() {

  return (
    <Router>
      <Routes>
      <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ranking"
          element={
            <ProtectedRoute>
              <Ranking />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          element={
            <ProtectedRoute>
              <News />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news/:slug"
          element={
            <ProtectedRoute>
              <NewsDetails />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/wylogowanie" element={<Logout />} />
        <Route path="/rejestracja" element={<Register />} />
        <Route path="/przypomnienie-hasla" element={<Remind />} />
        <Route path="/nowe-haslo" element={<NewPass />} />
        <Route path="/mbat" element={<MBAT />} />
        <Route path="/mbat/t/:token" element={<MBAT />} />
        <Route path="/mbat/e/:error" element={<MBAT />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Nav />
      <Drawer />
      <Footer />
    </Router>
  );
}

export default AppRoutes;
