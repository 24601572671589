import React, { useEffect } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import IMG from './img/img-remind.jpg';
import IMG_MOBILE from './img/img-remind-mobile.jpg';
import { RequestReset } from '../../features/user/authService';
import { selectRemindSent } from '../../features/user/userSlice';

interface remindProps {
  emailAddress: 'string';
}

function RemindPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const remindSuccess = useAppSelector(selectRemindSent)
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();

  const onSubmit = ({email}: FieldValues) => {
    dispatch(RequestReset(email))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  return (
    <PageLayout>
      <div className="page Login form-columns">
        <div className="image-column">
          <img className="form-image" src={IMG} />
        </div>
        <div className="form-column">
          <h1 className="space-bottom">Zapomniałeś hasła?</h1>
          <img className="form-image-mobile" src={IMG_MOBILE} />
          <p className="text-center">Wystarczy, że podasz swój e-mail, a my pomożemy Ci ustawić nowe hasło.</p>
          <form>
            <section className={ remindSuccess ? "form-step": "form-step active" }>
              <div className="input-item">
                <label htmlFor="email">E-mail</label>
                <input className={ errors.email && 'error-input' } type="text" id="email" {...register("email", {required: true, pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></input>
                {errors.email && errors.email.type === "pattern" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
                {errors.email && errors.email.type === "required" && (
                  <p className="error">Pole wymagane</p>
                )}
              </div>
              <Button onClick={ handleSubmit(onSubmit, onError) }>Dalej</Button>
            </section>
            <section className={ remindSuccess ? "form-step active": "form-step" }>
              <p>{"Wszystko jest na dobrej drodze. Na Twoją skrzynkę mailową ("+getValues('email')+") wysłaliśmy instrukcję resetowania hasła."} </p>
              <div className="center">
                {/*<IconCheck />*/}
              </div>
            </section>
            <div className="text-center margin-20">
              <Link to="/">Powrót do logowania</Link>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}

export default RemindPage
