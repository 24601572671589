import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Dashboard.scss";
import DashboardLineChart from "./DashboardLineChart";
import { ChartType, DateType } from "./DashboardBarChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import dayjs from "dayjs";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as ArrowNext } from "./arrow-next.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { GetStatsDay, GetStatsMonth, GetStatsYear } from "../../features/stats/statsService";
import { selectEmployees, selectStatsData } from "../../features/stats/statsSlice";
import 'dayjs/locale/pl';
import { selectUserData } from "../../features/user/userSlice";

interface DateSelectProps {
  dateCounter: number,
  dateType: DateType,
  setDateCounter: any
}

function DateSelect({dateCounter, dateType, setDateCounter}:DateSelectProps) {

  const [select, setSelect] = useState(<></>)

  useEffect(()=>{
    switch(dateType) {
      case 'week':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'week').startOf('week').format('DD.MM')+' - '+dayjs().add(dateCounter, 'week').endOf('week').format('DD.MM') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'month':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().locale('pl').add(dateCounter, 'month').format('MMMM YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'year':
        setSelect(
          <div className="DateSelect">
            <div className="prev-date" onClick={()=>setDateCounter((prevState:any) => prevState-1)}><Arrow /></div>
            <div className="date-range">{ dayjs().add(dateCounter, 'year').format('YYYY') }</div>
            <div className="next-date" onClick={()=>{if (dateCounter <0) {setDateCounter((prevState:any) => prevState+1)}}}><ArrowNext /></div>
          </div>
        )
      break;
      case 'full':
        setSelect(
          <div className="DateSelect">
            <div className="date-range">2021 - {dayjs().format('YYYY')}</div>
          </div>
        )
      break;
    }
  },[dateCounter, dateType])
  return (
    <>
      { select }
    </>
  )
}

function Dashboard() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserData);
  const [showEmployees, setShowEmployees] = useState(false)
  const [showInactiveEmployees, setShowInactiveEmployees] = useState(false)
  const [chartType, setChartType] = useState<ChartType>("device")
  const [dateType, setDateType] = useState<DateType>("week");
  const chartData = useAppSelector(selectStatsData)
  const devicesData = {
    device: chartData.reduce((sum:number, item:any) => sum + item.shops[0].device, 0),
    referral: chartData.reduce((sum:number, item:any) => sum + item.shops[0].referral, 0),
    balance: chartData.reduce((sum:number, item:any) => sum + item.shops[0].balance, 0),
  }
  const employees = useAppSelector(selectEmployees);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [selectedEmployeesData, setSelectedEmployeesData] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const colors = ['#15AFE6', '#FF0321', '#FF8D03', '#009B3E', '#8000FF'];
  const text = {
    device: {
      name: "urządzeń",
      title: "Całkowita ilość sprzedaży"
    },
    referral: {
      name: "poleceń",
      title: "Całkowita ilość poleceń"
    },
    balance: {
      name: "PLN",
      title: "Całkowita zebrana kwota"
    },
  }

  const [dateCounter, setDateCounter] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0)

  useEffect(()=>{
    if (dateType === 'week') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'week').startOf('week').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'week').endOf('week').format('YYYY-MM-DD')}))
    } else if (dateType === 'month') {
      dispatch(GetStatsDay({from: dayjs().add(dateCounter, 'month').startOf('month').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'month').endOf('month').format('YYYY-MM-DD')}))
    } else if (dateType === 'year') {
      dispatch(GetStatsMonth({from: dayjs().add(dateCounter, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().add(dateCounter, 'year').endOf('year').format('YYYY-MM-DD')}))
    } else if (dateType === 'full') {
      dispatch(GetStatsYear({from: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD'), to: dayjs().endOf('year').format('YYYY-MM-DD')}))
    }
  }, [dateCounter, dateType])

  useEffect(()=>{
    setSelectedEmployeesData(employees.filter((item:any, key:number)=> selectedEmployees.indexOf(item.referralCode) > -1))
  }, [selectedEmployees])

  const toggleEmployee = (id:string) => {
    if (selectedEmployees.indexOf(id) > -1) {
      setSelectedEmployees(selectedEmployees.filter((item:any)=> item !== id))
    } else {
      if (selectedEmployees.length < 5)
        setSelectedEmployees([...selectedEmployees, id])
    }
  }

  return (
    <PageLayout>
      <div className="page Dashboard">
        <div className="dash-head">
          { user?.salesPoint?.street && user?.salesPoint?.postalCode && user?.salesPoint?.cityName ? <div className="address">{`${user?.salesPoint?.street}, ${user?.salesPoint?.postalCode}, ${user?.salesPoint?.cityName}`}</div> : null}
          <div className="sales-point">Punkt sprzedaży - <span>{ user?.salesPoint?.popid }</span></div>
        </div>
        <div className="dropshadow-box chart-box">
          <div className="chart-head">
            <div className="chart-options">
              <div className="data-options">
                <DropDownTabs
                  handleChangeTab={(val: any) => {setChartType(val)}}
                  activeTab={chartType}
                  tabList={[{name: "Sprzedane urządzenia", value: "device"}, {name: "Polecenia", value: "referral"}]}
                  color={"#fff"}
                />
              </div>
              <div className="date-options">
                <DropDownTabs
                  handleChangeTab={(val: any) => {setDateType(val); setDateCounter(0)}}
                  activeTab={dateType}
                  tabList={[{name: "Tydzień", value: "week"}, {name: "Miesiąc", value: "month"}, {name: "Rok", value: "year"}, {name: "Cały okres", value: "full"}]}
                  color={"#fff"}
                />
                <DateSelect dateCounter={dateCounter} dateType={dateType} setDateCounter={setDateCounter} />
              </div>
            </div>
            <p className="sold-devices">Sprzedane w tym okresie - <strong>{ devicesData[chartType] } <span>{text[chartType].name}</span></strong></p>
          </div>
          <DashboardLineChart type={chartType} dateType={dateType} employeesData={selectedEmployeesData} employees={showEmployees} monthDays={parseInt(dayjs().add(dateCounter, 'month').endOf('month').format('DD'))} />
          <div className="chart-bottom">
            <div className="chart-bottom-options">
              <div className="checkbox-item chart-checkbox">
                <input type="checkbox" id="employees" checked={showEmployees} onChange={()=>setShowEmployees((val)=>!val)} />
                <label htmlFor="employees">Zobacz podział na sprzedawców</label>
              </div>
              { showEmployees &&
                <div className="checkbox-item chart-checkbox">
                  <input type="checkbox" id="iemployees" checked={showInactiveEmployees} onChange={()=> setShowInactiveEmployees((val)=>!val)} />
                  <label htmlFor="iemployees">Dodaj nieaktywnych sprzedawców</label>
                </div>
              }
            </div>
            { showEmployees &&
              <>
                <div className="chart-employees-top">
                  <div>
                    <h3>Wybierz sprzedawców do porównania*</h3>
                    <p>*Jednorazowo możesz wybrać maksymalnie 5 sprzedawców</p>
                  </div>
                  <div className="search-input">
                    <div className="input-item"><input type="text" placeholder="Wpisz imię sprzedawcy..." onChange={(e)=>setSearch(e.target.value)} /></div>
                  </div>
                </div>
                <div className="chart-employees">

                  { employees.filter((item:any,key:number) => {
                    if (!showInactiveEmployees && item.inactive) {
                      return false;
                    }
                    if (!showInactiveEmployees && item.inactive) {
                      return false;
                    }
                    if (search) { 
                      if (item.firstName.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.lastName.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                        return true
                      } else {
                        return false
                      }
                    } else {
                      return true
                    }
                  }) .map((item:any,key:number) => {
                    const popIndex = selectedEmployees.indexOf(item.referralCode)
                    const isSelected = popIndex > -1 ? true : false
                    return (
                      <div className={ !isSelected && selectedEmployees.length === 5 ? "sumup-box sumup-box-employee inactive" : 'sumup-box sumup-box-employee'} key={'employee-'+key} onClick={()=>toggleEmployee(item.referralCode)}>
                        <div className="sumup-top">
                          <div className="name"><span className={ selectedEmployees.indexOf(item.referralCode) > -1 ? "employee-checkmark active" : "employee-checkmark"}></span>{ item.firstName+' '+item.lastName[0]+'.' }</div>
                          <div className="sold">
                            <div className="chart-line" style={{backgroundColor: colors[selectedEmployees.indexOf(item.referralCode)]}}><div className="chart-line-point" style={{backgroundColor: colors[selectedEmployees.indexOf(item.referralCode)]}}></div></div>
                            { item[chartType]+' urządzeń' }
                          </div>
                        </div>
                      </div>
                    )
                  })}
                 </div>
              </>
            }
          </div>
        </div>
        <p className="chart-notice">Aktualizacje co 24 godziny. Ostatnia aktualizacja z dnia {dayjs().subtract(1, 'day').format('DD.MM.YYYY')} godz. 23:59</p>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
