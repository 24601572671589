import { createSlice, isAnyOf, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { UserDetails, Login, Register, Logout, RequestReset } from './authService';
import { RootState } from '../../store';

export interface UserState {
  isAuthenticated: boolean,
  userData: any,
  isLoading: boolean,
  token: {
    uuid: string
  },
  registerSuccess: boolean,
  remindSent: boolean,
  resetPassState: boolean
}

const initialState: UserState = {
  isAuthenticated: false,
  userData: {},
  isLoading: true,
  token: {
    uuid: ''
  },
  registerSuccess: false,
  remindSent: false,
  resetPassState: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(
      UserDetails.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.user.data
        state.isAuthenticated = true;
        state.token = action.payload.token;
      }
    )
    .addCase(
      Login.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.token = action.payload.data.token;
        state.isAuthenticated = true;
      }
    )
    .addCase(
      Logout.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.token = { uuid: '' };
        state.isAuthenticated = false;
      }
    )
    .addMatcher(
      isAnyOf(UserDetails.pending, Login.pending),
      (state) => {
        state.isLoading = true;
      }
    )
    .addMatcher(
      isAnyOf(Register.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.registerSuccess = action.payload.status.success;
      }
    )
    .addMatcher(
      isAnyOf(RequestReset.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.remindSent = action.payload.status.success;
      }
    )
    .addMatcher(
      isAnyOf(UserDetails.rejected, Login.rejected, Logout.fulfilled, Register.rejected),
      (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.userData = {};
        state.registerSuccess = false;
      }
    )
  },
});

// Exporting pieces of state to be used in components with useSelector Hook
export const selectUserData = (state: RootState) => state.user.userData;
export const selectToken = (state: RootState) => state.user.token;
export const selectAuthState = (state: RootState) => state.user.isAuthenticated;
export const selectIsLoading = (state: RootState) => state.user.isLoading
export const selectresgisterSuccess = (state: RootState) => state.user.registerSuccess
export const selectRemindSent = (state: RootState) => state.user.remindSent
export const selectResetState = (state: RootState) => state.user.resetPassState

// Reducer export
export default userSlice.reducer