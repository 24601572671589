import React, { useEffect, useState } from "react";
import PageLayout from "../../../components/PageLayout/PageLayout";
import newsBackground from "../../pages/News/img/img.png";
import { useColorPicker, useWindowDimensions } from "../../../hooks";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import "./NewsDetails.scss";
import Title from '../../../components/Title/Title'
import { API_URL, appFetch } from "../../../fetch";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useAppSelector } from "../../../hooks";
import { selectToken } from "../../../features/user/userSlice";

const News = ({ news }: any) => {

  return (
    <>
      <Title><span className="hide-desktop">{ news.content?.title }</span></Title>
      <div className="news-wrap">
        <div className={"news-item"}>
          <span
            className={"news-item-category"}
            style={{ backgroundColor: `${useColorPicker(news?.category)}` }}
          >
            {news?.categoryName}
          </span>

          <div className="news-image">
            <img src={news?.content?.modules?.find((module: any)=> module.type === "hero_image")?.file.url} alt="" />
          </div>
          <div className="news-body mobile">
            { news.content?.modules.map((item: any)=>{
              switch(item.type) {
                case "intro":
                  return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
                case "text":
                  return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
                case "button":
                  return <></>
                default:
                  return <div></div>
              }

            })}
          </div>
        </div>
      
        <div className="desktop-news">
          <div className="news-heading">
            <h2 className="news-title">{ news.content?.title}</h2>
            <div className="calendar">
              <Calendar /> <span className="date">{dayjs(news?.created?.date).format('DD-MM-YYYY')}</span>
            </div>
          </div>
          <div className="news-body">
            { news.content?.modules.map((item: any)=>{
              switch(item.type) {
                case "intro":
                  return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
                case "text":
                  return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
                case "button":
                  return <></>
                default:
                  return <div></div>
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

function NewsDetails() {
  const { width } = useWindowDimensions();
  const [newsItem, setNewsItem] = useState(false);
  const token = useAppSelector(selectToken);
  const { slug } = useParams();

  useEffect(() => {
    appFetch(
      "POST",
      API_URL,
      "/article/describe",
      { article: { slug: slug} },
      token?.uuid ? token?.uuid : false,
      (result: any) => {
        if (result.status && result.status.success) {
          setNewsItem(result.data);
        } else {
        }
      },
      () => {

      }
    );
  }, []);

  return (
    <PageLayout>
      <div className="page NewsDetails">
        { newsItem && <News news={newsItem} /> }
      </div>
    </PageLayout>
  );
}

export default NewsDetails;
