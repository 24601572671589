import React, { useEffect, useState } from "react";
import Routes from "./app/Routes";
import "./App.scss";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "./app/hooks";
import { UserDetails } from "./app/features/user/authService"; 
import dayjs from "dayjs";
import Loading from "./app/components/Loading/LoadingScreen/Loading";
import { selectUI } from "./app/features/ui/uiSlice";
import { selectAuthState, selectIsLoading } from "./app/features/user/userSlice";

function App() {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectIsLoading);
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("token");
  const ui = useAppSelector(selectUI);
  const {height} = useWindowDimensions();

  useEffect(()=>{
    const tkn = token && JSON.parse(token);
    if (window.location.pathname.indexOf('/mbat') >= 0) {
      setLoading(false)
    } else {
      if (tkn) {
        dispatch(UserDetails({navigate: false, isRedirect: false, token: tkn.uuid}))
      } else {
        setLoading(false)
      }
    }
  },[])

  return (
    <div className={ui.showMenu ? "app menu-open" : "app"} style={ui.showMenu ? {height: height} : {height: 'auto'}}>
      { !isLoading || !loading ? <Routes /> : <div className="app-loader"><Loading/></div> }
    </div>
  );
}

export default App;
