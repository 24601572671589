import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Ranking.scss";
import DATA from "../../data/owner-results-date-sort.json";
import { ChartType } from "../Dashboard/DashboardBarChart";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectRankingConsumer, selectRankingSales } from "../../features/ranking/rankingSlice";
import { GetRankingConsumer, GetRankingSales } from "../../features/ranking/rankingService";

function RankingRows({data}: any) {
  console.log(data)
  return (
    <>
    { data.map((user:any, key:number) => {
      return(
        <div className="user-ranking-row" key={user.referralCode}>
          <div className="position">{ user.position }</div>
          <div className="name">{ user.firstName }</div>
          <div className="value">{ user.counter }</div>
        </div>
      )})
    }
  </>
  )
}

function Ranking() {
  const [rankingType, setRankingType] = useState<ChartType>("device")
  const [season, setSeason] = useState("q2_23")
  const dispatch = useAppDispatch();
  const rankingSales = useAppSelector(selectRankingSales);
  const rankingConsumer = useAppSelector(selectRankingConsumer);

  useEffect(()=>{
    dispatch(GetRankingSales(season))
    dispatch(GetRankingConsumer(season))
  }, [rankingType, season])

  const text = {
    sales: {
      name: "Liczba sprzedaży",
      val: ""
    },
    referrals: {
      name: "Liczba poleceń",
      val: ""
    }
  }

  return (
    <PageLayout>
      <div className="page Ranking">
        <div className="title">
          <h1>Ranking BAT Partners</h1>
          <p>Ranking przedstawia pozycję Twoich pracowników na tle innych uczestników zarejestrowanych na platformie BAT Partner.</p>
        </div>
        <div className="rank-options">
          <DropDownTabs
            handleChangeTab={(val: any) => {setRankingType(val)}}
            activeTab={rankingType}
            tabList={[{name: "Sprzedane urządzenia", value: "device"}, {name: "Polecenia", value: "referral"}]}
          />
          <DropDownTabs
            handleChangeTab={(val: any) => {setSeason(val)}}
            activeTab={season}
            tabList={[{name: "Sezon II (2023)", value: "q2_23"}, {name: "Sezon I (2023)", value: "q1_23"}, {name: "Sezon III (2022)", value: "q4_22"}, {name: "Sezon II (2022)", value: "q3_22"}, {name: "Sezon I (2022)", value: "q2_22"}]}
          />
        </div>
        <div className="dropshadow-box">
          <div className="rank-box-head">

          </div>
          <div className="user-ranking">
            <div className="user-ranking-row user-ranking-head">
              <div>Pozycja</div>
              <div>BAT Partner</div>
              <div>{rankingType === "device" ? 'Liczba Sprzedaży' : 'Liczba poleceń'}</div>
            </div>
            <RankingRows data={rankingType === "device" ? rankingSales : rankingConsumer} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Ranking;
