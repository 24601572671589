import React, { ReactNode, useEffect } from 'react';
import {
  useLocation
} from 'react-router-dom'
import Header from '../header/Header';
import './PageLayout.scss';
import { useWindowDimensions, useScrollPosition} from '../../hooks';

interface Props {
  children: ReactNode;
  withBottom?: boolean;
  clsName?:string;
}

function PageLayout({children, withBottom,clsName}: Props) {
  const location = useLocation();
  const scrollPosition = useScrollPosition();
  const {width} = useWindowDimensions();
  const changeHeaderToFixed = scrollPosition > 32 && width < 1270;

  const getHeaderClass = (withBottom:boolean | undefined,changeHeaderToFixed:boolean) => {
    let cls = "PageLayout"
    if(withBottom) cls+=  " with-bottom"
    if(changeHeaderToFixed) cls+= " scroll"
    if(clsName) cls+=  ` ${clsName}`
    if(!isLoginPage()) cls+= " logged-in"
    return cls;
  }

  const isLoginPage = () => {
    if (location.pathname.indexOf('/wylogowanie') >= 0  || location.pathname.indexOf('/login') >= 0 || location.pathname.indexOf('/rejestracja') >= 0  ||  location.pathname.indexOf('/przypomnienie-hasla') >= 0 || location.pathname.indexOf('/aktywacja') >= 0  || location.pathname.indexOf('/nowe-haslo') >= 0 ) {
      return true;
    }
    return false;
  }

  useEffect(()=>{
    window.scrollTo(0,0);
  },[location.pathname])

  return (
    <div className={getHeaderClass(withBottom,changeHeaderToFixed)}>
      { isLoginPage() ? <Header castrated/> : <Header/>}
      { children }
    </div>
  );
}

export default PageLayout;
