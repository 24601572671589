import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch } from "../../fetch";
import type { RootState } from "../../store";

export const GetRankingConsumer = createAsyncThunk<any, string>(
  "ranking/GetRankingConsumer", 
  async (quarter, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      quarter: quarter
    }

    return appFetch("POST", API_URL, "/owner/ranking/consumer", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetRankingSales = createAsyncThunk<any, string>(
  "ranking/GetRankingSales", 
  async (quarter, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      quarter: quarter
    }

    return appFetch("POST", API_URL, "/owner/ranking/sales", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});