import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch } from "../../fetch";
import type { RootState } from "../../store";

interface DateType {
  from: string,
  to: string
}

export const GetStatsDay = createAsyncThunk<any, DateType>(
  "stats/GetStatsDay", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    return appFetch("POST", API_URL, "/owner/stats/day", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetStatsMonth = createAsyncThunk<any, DateType>(
  "stats/GetStatsMonth", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }

    return appFetch("POST", API_URL, "/owner/stats/month", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});

export const GetStatsYear = createAsyncThunk<any, DateType>(
  "stats/GetStatsYear", 
  async (date, {dispatch, getState}) => {
    const state = getState() as RootState;
    const data = {
      "stats": date
    }
    
    return appFetch("POST", API_URL, "/owner/stats/year", data, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});