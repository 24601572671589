import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, appFetch } from "../../fetch";
import type { RootState } from "../../store";

export const GetNews = createAsyncThunk<any, number>(
  "news/GetNews", 
  async (page, {dispatch, getState}) => {
    const state = getState() as RootState;

    return appFetch("POST", API_URL, "/article/list", false, state.user?.token?.uuid, (result:any) => {
      if(result.status && result.status.success) {
          return result.data;
      } else {
          //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
  }, (error:any) => {

  })

});