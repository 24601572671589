import { createSlice, isAnyOf, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { GetNews } from './newsService';
import { RootState } from '../../store';

export interface NewsState {
  list: any,
  isLoading: boolean,
}

const initialState: NewsState = {
  list: [],
  isLoading: false
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
    .addCase(
      GetNews.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.list = action.payload.data;
      }
    )
  },
});

// Exporting pieces of state to be used in components with useSelector Hook
export const selectNewsList = (state: RootState) => state.news.list

// Reducer export
export default newsSlice.reducer