import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import DATA from "../../data/owner-results-date-sort.json";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
  PointStyle,
  LineControllerDatasetOptions,
  ChartDataset,
  ScriptableContext,
  Filler
} from "chart.js";
import { useAppSelector, useWindowDimensions } from "../../hooks";
import { selectStatsData } from "../../features/stats/statsSlice";
import { isArrayLiteralExpression } from "typescript";
import dayjs from "dayjs";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Tooltip,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Filler
);

export type ChartType = "device"|"referral"|"balance";
export type DateType = "week"|"month"|"year"|"full";

interface Props {
  type: ChartType,
  dateType: DateType,
  employees: boolean,
  employeesData: any,
  monthDays: any
}

function DashboardLineChart({type, dateType, employees, employeesData, monthDays}:Props) {
  const [showEmployees, setShowEmployees] = useState(false)
  const reqData = useAppSelector(selectStatsData);
  const colors = ['#15AFE6', '#FF0321', '#FF8D03', '#009B3E', '#8000FF'];
  const { width } = useWindowDimensions();
  const canvas = useRef<any>(null)
  const gradient = canvas?.current?.ctx.createLinearGradient(0, 0, 0, 200);
  canvas && gradient?.addColorStop(0, 'rgba(21, 175, 230, .2)');   
  canvas && gradient?.addColorStop(1, 'rgba(21, 175, 230, 0.02)');

  const options = {
    responsive: true,
    aspectRatio: width < 1020 ? 1 : 4,
    interaction: {
      mode: 'index' as 'index'
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false
        },
        ticks: {
          precision: 0,
          font: {
            size: 12,
            family: "'Titillium Web', sans-serif"
          }
        }
      },
      x: {
        grid: {
          display: true,
          drawBorder: false
        },
        ticks: {
          font: {
            size: 12,
            family: "'Titillium Web', sans-serif"
          }
        }
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(255,255,255,1)',
        boxPadding: 10,
        cornerRadius: 12,
        bodyColor: '#001854',
        caretSize: 0, 
        borderColor: 'rgba(0,0,0,0.1)',
        borderWidth: 1,
        bodySpacing: 5,
        padding: 15,
        usePointStyle: true,
        bodyFont: {
          size: 14,
          family: "'Titillium Web', sans-serif"
        },
        callbacks: {
          title: function(context: any) { return ''; },
          labelPointStyle: function(context: any) {
            return {
                pointStyle: 'circle' as PointStyle,
                rotation: 0
            };
          }
        }
      }
    }
  };

  const dataSetLabel = {
    'device': 'Urządzenia',
    'referral': 'Polecenia',
    'balance': 'PLN'
  }

  let dataSets = [{
    label: dataSetLabel[type],
    data: reqData.map((item:any ,key:number)=>item.shops[0][type]),
    borderColor: [
      'rgba(21, 175, 230, 1)'
    ],
    backgroundColor: gradient,
    borderWidth: 1,
    pointRadius: 4,
    pointHitRadius: 10,
    pointBorderColor: '#fff',
    pointBackgroundColor: 'rgba(21, 175, 230, 1)',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(21, 175, 230, 1)',
    cubicInterpolationMode: 'monotone' as const,
    fill: true
  }]

  const getLabels = () => {
    if (dateType === "month") {
      const month = [];
      for (let index = 0; index < monthDays; index++) {
        month.push((index+1).toString())
      }
      return month;
    } else if (dateType === "year") {
      return ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];
    } else if (dateType === "full") {
      return reqData.map((item:any ,key:number) => dayjs(item.from).format('YYYY'))
    } else {
      return ['Pn.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Niedz.'];
    }
  }


  if (employees) {
    dataSets = employeesData.map((employee:any, eKey:number)=>{
      return {
        label: employee.firstName,
        data: reqData.map((item:any ,key:number)=> item.clerks.filter((k:any, i:number)=>k.referralCode === employee.referralCode)[0][type] ),
        borderWidth: 1,
        borderRadius: 12,
        borderColor: [colors[eKey]],
        backgroundColor: [colors[eKey]],
        pointRadius: 4,
        pointHitRadius: 10,
        pointBorderColor: '#fff',
        pointBackgroundColor: [colors[eKey]],
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: [colors[eKey]],
        cubicInterpolationMode: 'monotone' as const,
      }
    })
  }
  const chartData = {
    labels: getLabels(),
    datasets: dataSets
  }
 
  useEffect(()=>{

  }, [])

  return (
    <div className="chart-holder">
      <Chart ref={canvas} type="line" data={chartData} options={options} />
    </div>
  );
}

export default DashboardLineChart;
