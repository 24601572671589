import "./Nav.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "../../hooks";
import { ReactComponent as IconX } from "./img/icon-x.svg";
import { closeMenu, selectUI } from "../../features/ui/uiSlice";
import { ReactComponent as Logo } from "../header/img/logo-owner.svg";
import { useClickOutside } from "../../hooks";
//import { logout } from "../../store/actions/user";
import { ReactComponent as DashIcon } from "./img/home.svg";
import { ReactComponent as RankIcon } from "./img/chart.svg";
import { ReactComponent as NewsIcon } from "./img/news.svg";
import { ReactComponent as LogoutIcon } from "./img/logout.svg";
import { selectUserData } from "../../features/user/userSlice";
import { Logout } from "../../features/user/authService";

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ui = useAppSelector(selectUI);
  const user = useAppSelector(selectUserData);
  const {height, width} = useWindowDimensions();
  let node = useClickOutside(() => closeNav());

  const navItems = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: <DashIcon />
    },
    {
      name: "Ranking",
      link: "/ranking",
      icon: <RankIcon />
    },
    {
      name: "News",
      link: "/news",
      icon: <NewsIcon />
    }
  ];

  const closeNav = () => {
    dispatch(closeMenu());
  };

  const navClass = () => {
    if (location.pathname === "/dashboard" || location.pathname === "/ranking" || location.pathname.indexOf('/news') >= 0) {
      if ( width < 1270) {
        if (ui.showMenu) {
          return "nav active"
        } else {
          return "nav"
        }
      } else {
        return "nav active"
      }
    } else {
      return "nav";
    }
  }

  return (
    <div ref={node} className={navClass()} style={{height: height}}>
      <div className="nav-inner">
        <div className="logo">
          <Logo />
        </div>
        <div className="close" onClick={() => closeNav()}>
          <IconX />
        </div>
        <div className="user-name">
          <div className="name">{ user?.owner?.firstName }</div>
          <div className="title">Właściciel</div>
        </div>
        <div className="nav-links">
          { navItems.map((item, key) => {
            return (
              <NavLink
                key={`nav-item-${key}`}
                className="nav-item"
                to={item.link}
                onClick={() => {closeNav();  }}
              >
                {item.icon}
                <span>{item.name}</span>
              </NavLink>
            );
          })}
        </div>
        <div className="nav-footer">
          <span
            className="nav-item"
            onClick={() => {
              dispatch(Logout({navigate: navigate}));
              closeNav();
            }}
          >
            <LogoutIcon />
            <span>Wyloguj się</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Nav;
