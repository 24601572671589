import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import userReducer from './features/user/userSlice';
import uiReducer from './features/ui/uiSlice';
import newsReducer from './features/news/newsSlice';
import rankingReducer from './features/ranking/rankingSlice';
import statsReducer from './features/stats/statsSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    counter: counterReducer,
    ui: uiReducer,
    news: newsReducer,
    ranking: rankingReducer,
    stats: statsReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
